<template>
  <div class="banner">
    <bordure-row
      title="分享推广"
      shadow
      style="margin-bottom: 30px; margin-top: 30px"
    >
      <div class="extend_box">
        <div class="board">
          <p class="extend_title">数据看板</p>
          <div class="board_gaine">
            <div class="gaine_item">
              <img :src="require('@/assets/imgs/e2.png')" alt="" />
              <div class="gaine_item_conten">
                <p>账户余额(元)</p>
                <span v-if="MemberInfo"
                  >￥{{ MemberInfo.memberEntity.balance }}</span
                >
              </div>
            </div>
            <div class="gaine_item">
              <img :src="require('@/assets/imgs/e3.png')" alt="" />
              <div class="gaine_item_conten">
                <p>直接推荐用户数</p>
                <span v-if="MemberInfo">{{ MemberInfo.inviteCount }}</span>
              </div>
            </div>
            <div class="gaine_item">
              <img :src="require('@/assets/imgs/e1.png')" alt="" />
              <div class="gaine_item_conten">
                <p>累计提取金额(元)</p>
                <span v-if="MemberInfo"
                  >￥{{ MemberInfo.memberEntity.withdrawBalance }}</span
                >
              </div>
            </div>
            <div class="gaine_item">
              <img :src="require('@/assets/imgs/e4.png')" alt="" />
              <div class="gaine_item_conten">
                <p>间接推荐用户数</p>
                <span v-if="MemberInfo">{{
                  MemberInfo.indirectInviteCount
                }}</span>
              </div>
            </div>
          </div>
          <div>
            <p class="extend_title">提现设置</p>
            <div class="gaine_withdraw">
              <img
                :src="require('@/assets/imgs/e1.png')"
                alt=""
                style="width: 50px; height: 50px; margin-right: 10px"
              />
              <!-- <el-button type="danger" size="medium" @click="">申请提现</el-button> -->
              <el-button type="danger" size="medium" @click="withdraw"
                >提现</el-button
              >
            </div>
            <p class="gaine_withdraw_tip">
              注意:为避免影响您的提现时间,请主动添加客服微信,并将提现单号发给客服！
            </p>
          </div>
        </div>
        <div class="official">
          <div>
            <p class="extend_title">公告信息</p>
            <div class="extend_share">
              <el-input
                v-model="shareUrl"
                size="small"
                disabled
                class="extend_share_input"
              ></el-input>
              <el-button
                type="primary"
                size="small"
                @click="copyWay(shareUrl)"
                style="margin-left: 20px"
              >
                分享推广
              </el-button>
            </div>
          </div>
          <div>
            <p class="extend_title">推荐规则</p>
            <p>
              A用户推广给B用户，B每进行一次积分充值，A即可获取一定比例奖励；
            </p>
            <p>
              B推荐给C用户，C每进行一次积分充值，A即依然可以获取一定比例奖励。
            </p>
            <p>推广规则一年内有效</p>
          </div>
          <div>
            <p class="extend_title">提现规则</p>
            <p>1、用户先点击“提现申请”需求</p>
            <p>2、实际提现金额需要满100元时才可提现</p>
            <p>3、暂时仅支持微信提现</p>
            <p>4、提现金额已经扣除各类税费</p>
          </div>
          <div>
            <p class="extend_title">客服中心</p>
            <div class="official_Serve">
              <img
                :src="$store.state.ServeQR"
                alt=""
                width="150"
                height="150"
              />
              <span class="official_notice">注意:如需提现请与客服联系</span>
            </div>
          </div>
        </div>

        <div class="flow">
          <p class="extend_title">流程实例</p>
          <div>
            <img :src="require('@/assets/imgs/tglct.png')" alt="" />
          </div>
        </div>
      </div>
    </bordure-row>

    <bordure-row
      title="推广记录"
      shadow
      style="margin-bottom: 30px; margin-top: 30px"
    >
      <div style="padding: 0 41px 0">
        <el-tabs v-model="tabActive">
          <el-tab-pane label="推荐记录" name="1">
            <el-table :data="inviteList.list" style="width: 100%" size="mini">
              <el-table-column
                prop="memberPhone"
                label="手机号[被推荐人]"
              ></el-table-column>
              <el-table-column
                prop="upMemberName"
                label="推荐人"
              ></el-table-column>
              <el-table-column label="直属">
                <template slot-scope="scope">
                  <div v-if="MemberInfo">
                    <span
                      v-if="
                        scope.row.upMemberName ==
                        MemberInfo.memberEntity.memberName
                      "
                      style="color: red"
                    >
                      <el-tag type="success" size="mini">直接</el-tag>
                    </span>
                    <span v-else style="color: #409eff">
                      <el-tag type="info" size="mini">间接</el-tag>
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="registerTime"
                label="注册时间"
              ></el-table-column>
            </el-table>
            <!-- 分页器 -->
            <div style="display: flex; justify-content: flex-end">
              <el-pagination
                style="margin: 20px 0 37px"
                @size-change="inviteListSizeChange($event)"
                @current-change="inviteListCurrentChange($event)"
                :current-page="inviteList.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="inviteFormData.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="inviteList.totalCount"
              >
              </el-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane label="充值记录" name="2">
            <el-table
              :data="memberRewardList.list"
              style="width: 100%"
              size="mini"
            >
              <el-table-column
                prop="refMemberChangeName"
                label="被推荐人"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="充值时间"
              ></el-table-column>
              <el-table-column
                prop="rechargeAmount"
                label="支付金额"
              ></el-table-column>
              <el-table-column prop="rebateRate" label="提成比例">
                <template slot-scope="scope">
                  <span>{{ scope.row.rebateRate * 100 }}%</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="awardAmount"
                label="提成金额(综合税后)"
              ></el-table-column>
            </el-table>
            <div style="display: flex; justify-content: flex-end">
              <el-pagination
                style="margin: 20px 0 37px"
                @size-change="memberRewardSizeChange($event)"
                @current-change="memberRewardCurrentChange($event)"
                :current-page="memberRewardList.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="memberRewardFormData.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="memberRewardList.totalCount"
              >
              </el-pagination>
            </div>
          </el-tab-pane>

          <el-tab-pane label="提现记录" name="3">
            <el-table
              :data="withdrawPoint.list"
              style="width: 100%"
              size="mini"
            >
              <el-table-column prop="orderCode" label="单号"></el-table-column>
              <el-table-column prop="payType" label="提现方式">
                <template slot-scope="scope">
                  <div v-if="scope.row.payType == 1">线下提现</div>
                  <div v-if="scope.row.payType == 2">微信提现</div>
                  <div v-if="scope.row.payType == 3">支付宝提现</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="withdrawMoney"
                label="提现金额"
              ></el-table-column>
              <el-table-column prop="orderState" label="提现状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.orderState == 1">已提交</div>
                  <div v-else-if="scope.row.orderState == 2">审核通过</div>
                  <div v-else-if="scope.row.orderState == 3">拒绝</div>
                  <div v-else-if="scope.row.orderState == 4">打款成功</div>
                  <div v-else>未知状态</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="withdrawTime"
                label="提现时间"
              ></el-table-column>
            </el-table>
            <div style="display: flex; justify-content: flex-end">
              <el-pagination
                style="margin: 20px 0 37px"
                @size-change="withdrawSizeChange($event)"
                @current-change="withdrawCurrentChange($event)"
                :current-page="withdrawPoint.page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="withdrawFormData.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="withdrawPoint.totalCount"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </bordure-row>

    <el-dialog title="提现" :visible.sync="exVisible" width="800px">
      <el-form
        :model="exform"
        ref="form"
        :rules="rules"
        label-width="100px"
        :inline="false"
        size="medium"
        v-loading="dataLoading"
      >
        <el-form-item label="提现类型：">
          <div>
            <el-radio-group v-model="exform.payType">
              <el-radio label="2" border>微信提现</el-radio>
              <el-radio label="3" border>支付宝提现</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>

        <el-form-item label="真实姓名：" prop="cardReadName">
          <div>
            <el-input
              v-model="exform.cardReadName"
              placeholder="请输入真实姓名(用于核对提现账号)"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          label="提现账号："
          prop="withdrawAccount"
          v-if="exform.payType == 3"
        >
          <div>
            <el-input
              v-model="exform.withdrawAccount"
              placeholder="请输入提现账号"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="提现金额：" prop="withdrawMoney">
          <div v-if="MemberInfo">
            <el-input
              v-model="exform.withdrawMoney"
              type="number"
              min="0"
              :max="MemberInfo.memberEntity.balance"
              placeholder="请输入提现金额"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="提现备注：" prop="remark">
          <div>
            <el-input
              v-model="exform.subRemark"
              type="textarea"
              :rows="3"
              placeholder="提现备注"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="exVisible = false">返回</el-button>
        <el-button
          type="primary"
          @click="confirmWithdraw('form')"
          :disabled="dataLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMemberInfo,
  queryInviteMember,
  memberReward,
  applyFinanceWithdraw,
  withdrawList,
} from "@/request/api"; //这里是引入请求

import utils from "@/utils";

export default {
  data() {
    return {
      tabActive: "1",
      inviteList: {
        list: [],
      },
      exVisible: false,
      exform: {
        payType: "2",
        withdrawAccount: "",
        withdrawMoney: "",
        subRemark: "",
      },
      rules: {
        cardReadName: {
          required: true,
          message: "请输入真实姓名",
          trigger: "blur",
        },
        withdrawAccount: {
          required: true,
          message: "请输入提现账号",
          trigger: "blur",
        },
        withdrawMoney: {
          required: true,
          message: "请输入提现金额",
          trigger: "blur",
        },
      },
      inviteFormData: {
        page: 1,
        limit: 10,
      },
      MemberInfo: null,
      memberRewardList: {
        list: [],
      },
      memberRewardFormData: {
        page: 1,
        limit: 10,
      },

      withdrawPoint: {
        list: [],
      },
      withdrawFormData: {
        page: 1,
        limit: 10,
      },
      dataLoading: false,
    };
  },
  mounted() {
    this.gainMemberInfo();
    this.transitInviteMember();
    this.getMemberReward();
    this.getWithdrawList();
  },
  methods: {
    gainMemberInfo() {
      getMemberInfo().then((data) => {
        //获取用户信息
        this.MemberInfo = data.data;
      });
    },

    // 复制
    copyWay(val) {
      this.$comm.copy(val + "\n" + this.$store.state.copySuffix);
    },
    transitInviteMember() {
      queryInviteMember(this.inviteFormData).then((data) => {
        this.inviteList = data.data;
      });
    },

    getMemberReward() {
      memberReward(this.memberRewardFormData).then((data) => {
        this.memberRewardList = data.page;
      });
    },

    inviteListSizeChange(e) {
      this.inviteFormData.limit = e;
      this.transitInviteMember();
    },
    inviteListCurrentChange(e) {
      this.inviteFormData.page = e;
      this.transitInviteMember();
    },

    memberRewardSizeChange(e) {
      this.memberRewardList.limit = e;
      this.getMemberReward();
    },
    memberRewardCurrentChange(e) {
      this.memberRewardList.page = e;
      this.getMemberReward();
    },

    // 提现
    withdraw() {
      if (this.MemberInfo.memberEntity.balance < 100) {
        this.$message.error("账户余额满100元时才可提现");
        return;
      }
      this.exVisible = true;
    },
    initExform() {
      this.exform.payType = "2";
      this.exform.withdrawMoney = "";
      this.exform.subRemark = "";
    },
    confirmWithdraw(formName) {
      if (this.$store.state.memberInfo == null) {
        return;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          applyFinanceWithdraw(this.exform)
            .then((data) => {
              this.gainMemberInfo();
              this.getWithdrawList();
              this.$message.success("提交成功");
              this.exVisible = false;
              this.initExform();
            })
            .finally(() => {
              this.dataLoading = false;
            });
        }
      });
    },

    // 提现列表
    getWithdrawList() {
      withdrawList().then((data) => {
        this.withdrawPoint = data.page;
      });
    },

    withdrawSizeChange(e) {
      this.withdrawPoint.limit = e;
      this.getWithdrawList();
    },
    withdrawCurrentChange(e) {
      this.withdrawPoint.page = e;
      this.getWithdrawList();
    },
    handleMoney(val, oldval) {
      if (val > this.MemberInfo.memberEntity.balance) {
        this.exform.withdrawMoney = this.MemberInfo.memberEntity.balance;
      } else if (val < 0) {
        this.exform.withdrawMoney = 0;
      }
    },
  },
  watch: {
    "exform.withdrawMoney": "handleMoney",
  },
  computed: {
    shareUrl: function () {
      if (this.$store.state.shareObj) {
        this.$store.state.shareObj.shref;
      }
      return "";
    },
  },
};
</script>

<style lang="less">
.extend_title {
  font-size: 18px;
  font-weight: 500;
  color: #409eff;
  line-height: 27px;
  padding: 10px 0;
}

.extend_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  padding: 20px 0;

  .official {
    box-sizing: border-box;
    width: 45%;
    font-size: 14px;
    margin: 20px;
    padding: 0 20px;
    color: #333;

    p {
      line-height: 1.8;
    }

    .extend_share {
      display: flex;

      .extend_share_input {
        input {
          color: #333;
        }
      }
    }

    .official_Serve {
      display: flex;
      align-items: flex-end;

      .official_notice {
        color: red;
        font-size: 14px;
        padding: 7px 10px;
      }
    }
  }

  .board {
    box-sizing: border-box;
    width: 45%;
    margin: 20px;
    padding: 0 20px;

    .board_gaine {
      display: flex;
      flex-wrap: wrap;

      .gaine_item {
        display: flex;
        width: 40%;
        margin: 20px 10px;
        padding: 20px 10px;
        border: 1px solid #f1f1f1;
        align-items: center;
        text-align: center;

        img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }

        .gaine_item_conten {
          flex: 1;

          p {
            font-size: 14px;
          }

          span {
            font-size: 24px;
          }
        }
      }
    }

    .gaine_withdraw {
      display: flex;
      align-items: center;
    }

    .gaine_withdraw_tip {
      color: #d81e06;
      margin-top: 10px;
      line-height: 1.5;
      font-size: 12px;
    }
  }
}
</style>